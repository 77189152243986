import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Props as BenefitsProps } from 'app/components/Benefits'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { FlashNews, Props as FlashNewsProps } from 'app/components/FlashNews'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useContext, useEffect, useState } from 'react'

import { BottomMenuItem, Props as BottomMenuItemProps } from './BottomMenuItem'
import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { ModalClose } from './ModalClose'
import { ModalIBE } from './ModalIBE'
import { Navigation } from './Navigation'
import { Toolbars } from './Toolbars'

export interface Props {
  benefits?: BenefitsProps
  bottomMenuItems: BottomMenuItemProps[]
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
  flashNewsProps?: FlashNewsProps
}

export const Header = memo(function Header({
  bottomMenuItems,
  languagesList,
  menuItems,
  pageID,
  flashNewsProps,
}: Props) {
  const {
    languageCode,
    languagePrefix,
    logo,
    logoSticky,
    quotationPageURL,
    siteName,
    variant,
  } = useContext(HeaderContext) as any

  const [_locked, setLocked] = useLockedBody()
  const [scroll, setScroll] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)
  const [modalIBEStatus, setModalIBEStatus] = useState(false)

  const quotationPageSlug = quotationPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const quotationRealPageURL = languagePrefix
    ? `/${languagePrefix}/${quotationPageSlug}`
    : `/${quotationPageSlug}`

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > 200)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {flashNewsProps ? <FlashNews {...flashNewsProps} /> : null}

        <Head
          className={scroll || variant === 'compact' ? 'fixed' : undefined}
          dial={4}
          row
          variant={variant}
        >
          <Hamburger
            className={`hamburger-menu${modalStatus ? ' open' : ''}`}
            dial={4}
            onClick={() => {
              if (!modalStatus) {
                setLocked(true)
                setModalStatus(true)
              } else {
                setLocked(false)
                setModalStatus(false)
              }
            }}
            row
          >
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
          </Hamburger>

          <Languages
            languagesList={languagesList}
            isSticky={scroll || variant === 'compact'}
            pageID={pageID}
          />

          {logo ? (
            <LogoWrapper className="logo-wrapper">
              <Link to={languagePrefix ? `/${languagePrefix}` : '/'}>
                {logoSticky ? (
                  <Media lessThan="desktopSmall">
                    <Logo
                      className="logo"
                      src={logoSticky}
                      alt={siteName}
                      width="175"
                      height="60"
                    />
                  </Media>
                ) : null}
                {logo ? (
                  <Media greaterThanOrEqual="desktopSmall">
                    <Logo
                      className="logo"
                      src={scroll || variant === 'compact' ? logoSticky : logo}
                      alt={siteName}
                      width="250"
                      height="86"
                    />
                  </Media>
                ) : null}
              </Link>

              <ChainLink
                className="chain-link"
                href="https://www.mirahotels.com"
                target="_blank"
                rel="noopener"
              />
            </LogoWrapper>
          ) : null}

          <Buttons row>
            {bottomMenuItems[0] ? (
              <VoucherCTA {...bottomMenuItems[0]} variant="header" />
            ) : null}
            <Media greaterThanOrEqual="desktopSmall">
              <IBECTA
                label={useVocabularyData('book', languageCode)}
                // icon="calendar"
                onClick={() => {
                  if (!modalIBEStatus) {
                    setLocked(true)
                    setModalIBEStatus(true)
                  } else {
                    setLocked(false)
                    setModalIBEStatus(false)
                  }
                }}
              />
              {quotationPageURL ? (
                <QuotationCTA
                  label={useVocabularyData('request', languageCode)}
                  // icon="mail"
                  URL={quotationRealPageURL}
                  variant="primary"
                />
              ) : null}
            </Media>
          </Buttons>
        </Head>

        <Modal className={`modal-nav${modalStatus ? ' open' : ''}`}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              setLocked(false)
              setModalStatus(false)
            }}
          />
          <Navigation
            bottomMenuItems={bottomMenuItems}
            menuItems={menuItems}
            modalStatus={modalStatus}
          />

          <Media lessThan="desktopSmall">
            <Toolbars quotationPageURL={quotationRealPageURL} />
          </Media>
        </Modal>

        <Media greaterThanOrEqual="desktopSmall">
          <Modal className={modalIBEStatus ? ' open' : undefined}>
            <ModalClose
              languageCode={languageCode}
              onClick={() => {
                setLocked(false)
                setModalIBEStatus(false)
              }}
              variant="right"
            />
            <ModalIBE />
          </Modal>
        </Media>

        <Media lessThan="desktopSmall">
          <Toolbars quotationPageURL={quotationRealPageURL} />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.06)} 0 3px 12px;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  .hamburger-menu {
    .hamburger-lines {
      div {
        background: ${theme.colors.variants.primaryLight};
      }
    }
  }
  .logo-wrapper {
    top: 0.9375rem;
    .logo {
      width: auto;
      height: 3.75rem;
    }
    .chain-link {
      width: 3.75rem;
      height: 3.75rem;
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  background: linear-gradient(
    ${rgba(theme.colors.variants.neutralDark1, 0.7)},
    ${rgba(theme.colors.variants.neutralDark1, 0)}
  );
  padding: 3.75rem 1.875rem 5rem;
  position: relative;
  &.fixed {
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}
  }

  @media (max-width: 1199px) {
    ${HeaderFixedStyle}

    animation: none !important;
    padding: 1.1875rem 2rem !important;

    .hamburger-menu {
      &.open {
        width: 2.5rem;
        height: 1.375rem;
        .hamburger-lines {
          div {
            width: 1.75rem;
            position: absolute;
            top: 0.5625rem;
            left: -0.25rem;
            &:nth-of-type(1) {
              transform: rotate(45deg);
            }
            &:nth-of-type(2) {
              display: none;
            }
            &:nth-of-type(3) {
              transform: rotate(-45deg);
            }
          }
        }
      }
      .hamburger-lines {
        div {
          background: ${theme.colors.variants.secondaryLight} !important;
          transition: none !important;
          &:nth-of-type(2) {
            width: 100%;
          }
        }
      }
    }
    .logo-wrapper {
      .logo {
        height: 2.25rem !important;
        top: 0.75rem !important;
      }
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Hamburger = styled(FlexBox)`
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      div {
        &:nth-of-type(2) {
          width: 2.5rem;
        }
      }
    }
  }
`

const HamburgerLines = styled.div``

const HamburgerLine = styled.div`
  width: 2.5rem;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 1.25rem;
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    width: 1.875rem;
    margin: 0.5rem 0;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 2.9375rem;
  left: 50%;
  transform: translateX(-50%);
`

const Logo = styled.img`
  width: auto;
  height: 5.375rem;
  margin: auto;
`

const ChainLink = styled.a`
  width: 5.375rem;
  height: 5.375rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const Buttons = styled(FlexBox)``

const IBECTA = styled(Button)``

const VoucherCTA = styled(BottomMenuItem)``

const QuotationCTA = styled(Button)`
  margin-left: 0.25rem;
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }

  @media (max-width: 1199px) {
    &.modal-nav {
      top: 3.75rem;
      z-index: 2;
      > div {
        &:first-of-type {
          display: none;
        }
        &:last-of-type {
          div {
            transform: none !important;
          }
        }
      }
    }
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
